@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

.logo-c{
  color: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Myth", sans-serif;
  font-weight: 500;
  font-size: 22px;
  .logo-img{
    max-height: 40px;
    max-width: 40px;
    margin-right: 8px;
  }
  &.large{
    .logo-img{
      max-height: 52px;
      max-width: 52px;
      margin-right: 12px;
    }
  }


  @media screen and (min-width: $desktop-width) {
    &.tiny-not-mobile{
      font-size: 11px;
      flex-wrap: wrap;
      line-height: 11px;
    }
  }
  &.large{
    font-size: 32px;
  }
  &.white{
    color: #FFF;
  }
}