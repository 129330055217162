@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

.favicon{
  font-family: "Myth", sans-serif;
  font-weight: 500;

  display: flex;
  height: 100%;
  // height: 600px;
  background-color: black;
  justify-content: center;
  align-items: center;
  .favicon-elm{
    position: relative;
    display: flex;
    margin: 20px;
    justify-content: center;
    align-items: center;
    height: 120px;
    width: 120px;
    background-color: $primary-color;
    &.admin{
      background-color: #FFF;
    }
    > div{
      display: flex;
      > .v,> .b,> .a{
        color: #FFF;
        display: block;
        font-size: 90px;
        font-weight: 500;
      }
      > .v{
        margin: -1px 0 0 0;
        transform: scale(0.97);
      }      
      > .b{
        margin: 0 0 0 -18px;
        transform: skew(-24deg, 0deg);
      }
      > .a{
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        position: absolute;
        color: $primary-color;
        text-shadow: 
          0px -13px 0px #FFF,
          0px 6px 0px #FFF,
          -6px 0px 0px #FFF,
          6px 0px 0px #FFF;
      }
    }
    &.admin > div{
      > .v,> .b,> .a{
        color: $primary-color;
      }
    }
  }
}