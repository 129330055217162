@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

.center-div-outer {
  .center-div-middle{
    > :first-child.sitemap-modal{
      max-width: 1200px;
    }
  }
}


.search-comp{
  .title-div{
    padding: 4px;
    font-size: 14px;
    text-align: left;
    display: flex;
    align-items: center;
    .image{
      height: 60px;
      display: flex;
      width: 90px;
      min-width: 90px;
      justify-content: center;
      img,svg{
        border-radius: 4px;
        margin-right: 12px;
        height: 100%;
      }
    }
  }
  .copy-input{
    border-width: 0px;
    input{
      padding-left: 8px;
      font-size: 15px;
    }
  }
  .volume{
    input{
      max-width:1280px;
    }
  }
  .language {
    input{
      max-width: 80px;
    }
  }
  .carousel-header{
    background-color: #FFF;
  }
  .left{
    text-align: left;
  }
  .box{
    display: flex;
    textarea{
      flex: 1;
    }
    .button-element{
      margin-left: 20px;
      width: auto;
      button{
        padding-right: 40px;
      }
    }
  }
  .content-block {
    .buttons{
      display: flex;
      justify-content: flex-end;
      .button-element{
        width: 40px;
      }
      &.left .button-element{
        margin-right: 12px;
      }
      &.right .button-element{
        margin-left: 12px;
      }
      .new-article{
        width: auto;
        button{
          padding-right: 40px;
        }
      }
    }
  }
  
  .search-comp-line{
    margin: 8px 0 20px 0;
    background-color: rgba(150,150,150,1);
    width: 100%;
    height: 1px;
  }

  .sitemap-modal{
    .carousel-header{
      margin: 0;
    }
    .modal-content{
      background-color: $background-color;
    }
    .copy-input{
      textarea{
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}