
@import '../../../css/styles/colors';

.switch-toggle{
  cursor: pointer;
  position: relative;
  margin: 0 8px;
  background-color: rgba(210,210,210,1);
  transition: background-color 0.3s cubic-bezier(.71,-.29,.12,.63);
  .gray-cover{display: none;}
  &.disable{
    cursor: not-allowed;
    .gray-cover{
      display: block;
      position: absolute;
      top: 0px;left: 0px;
      height: 100%;width: 100%;
      border-radius: 25px;
      background-color: rgba(0,0,0,.15);
    }
  }
  .dot{
    position: absolute;
    background-color: #FFF;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: left 0.3s cubic-bezier(.71,-.29,.12,.63);
    .text{
      width: 100%;height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .text-on,.text-off{ 
        position: absolute;
        transition: opacity 0.6s;        
      }
      .text-off{ 
        opacity: 1;
      }
      .text-on{ 
        opacity: 0; 
      }
    }
  }
  .line{
    position: absolute;
    height: 40%;
    top: 30%;
    width: 1px;
    border-radius: 5px;
    background-color: rgba(255,255,255,1);
  }
  .circle{
    position: absolute;
    border-radius: 50%;
    border: 1px solid rgba(255,255,255,1);
    transform: scale(0.5);
  }
  &.on {
    background-color: $primary-color;
    .dot .text{
      .text-off{ 
        opacity: 0;
      }
      .text-on{ 
        opacity: 1; 
      }
    }
  }
}
.sandbox .switch-toggle.on{background-color: $sandbox-primary-color;}