.library-comp{
  table{
    .username-column{
      margin: 4px 0;
      display: flex;
      align-items: center;
      img,.svg-container {
        margin: 0 8px;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background-color: rgba(220,220,220,1);
        box-shadow: 0px 3px 8px 0px rgba(0,0,0,.1);
      }
      .svg-container{
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
          height: 24px;
          width: 24px;
          .real-path{
            fill: #555;
          }
        }
      }
      .text{
        text-align: left;
        flex: 1;
        .display-name{
          margin: 2px 0 0;
          font-weight: 700;
          font-size: 14px;
          color: rgba(0,0,0,.8);
        }
      }
    }
  }


  .center-div-outer .center-div-middle > :first-child.librar-main-modal{
    max-width: 600px;
  }
  .librar-main-modal{
    .modal-content{
      padding: 0px;
    }
    .carousel-header{
      padding: 0 40px 0 0;
      margin: 0;
    }
    .carousel-container{
      > div{
        padding: 20px;
      }
    }
    .course-details{
      .buttons{
        margin: 20px 0 0 0;
        display: flex;
        justify-content: space-between;
        > div {
          display: flex;
          &.right{
            .button-element{
              margin-right: 20px;
              &:last-of-type{margin-right: 0px;}
            }
          }
        }
      }
    }
  }
}