



.worlds-comp{
  .world-name{
    display: flex;
    align-items: center;
    img,svg{
      border-radius: 4px;
      margin: 4px 8px;
      height: 44px;
      width: 44px;
    }
    .text{
      text-align: left;
    }
  }
  .world-modal-buttons{
    display: flex;
    flex-flow: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin:0 0 12px;
    .switch-toggle{
      margin: 0;
      .text{
        line-height: 14px;
        font-size: 12px;
        text-align: center;
      }
    }
  }
}