@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

.center-div-outer {
  .center-div-middle{
    > :first-child.article-modal{
      max-width: 620px;
      .modal-content{
        padding: 0px;
        background-color: $background-color;
      }
    }
  }
}

.article-modal{
  .carousel-header{
    margin: 0px;
    padding-right: 40px;
  }
  .carousel-item{
    padding: 20px;
  }
  select,textarea{
    margin: 0 0 12px;
  }
  .button-element{
    margin: 24px 0 0 0;
  }
  .articles{
    .header-row{
      display: flex;
      justify-content: space-between;
      .button-element{
        button{
          padding-right: 40px;
        }
      }
    }
  }
  .article{
    margin: 12px 0px;
    padding: 12px;
    background-color: #FFF;
    border-radius: 5px;
    display: flex;
    align-items: center;
    
    .article-details{
      flex: 1;
      .title{
        font-weight: 800;
        margin-bottom: 12px;
      }

    }
    .button-element{
      margin: 0px 0px 0px 20px;
      width: 40px;
      button{
        color: rgba(0,0,0,0);
      }
    }
  }
}