@import './colors'; 
@import './variables'; 

@import './buttons';
@import './input';
@import './text';


@font-face { font-family: 'Myth';src: url('/css/fonts/MYTH-Light.otf'); font-weight: 100;}
@font-face { font-family: 'Myth';src: url('/css/fonts/MYTH-Regular.otf'); font-weight: 400;}
@font-face { font-family: 'Myth';src: url('/css/fonts/MYTH-Medium.otf'); font-weight: 500;}
@font-face { font-family: 'Myth';src: url('/css/fonts/MYTH-Bold.otf'); font-weight: 800;}


*{
  margin: 0;
  padding: 0;
  box-sizing:border-box; 
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box; 
  -webkit-overflow-scrolling: touch;
}
html,
body {
  height: 100%;
  margin: 0;
  font-family:  "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-color;
  color: $base-font-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",monospace;
}
#root{
  position: relative;
  height: 100%;
}
.app{
  height: 100%;
  display: flex;
  -webkit-overflow-scrolling: touch;
  @media screen and (min-width: $desktop-width) {
    &.top-navi{
      flex-flow: column;
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .app{
    flex-direction: column;
  }
}

#content-container{
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  #content{
    flex: 1;
    padding: 20px;
  }
}

@media screen and (max-width: $mobile-width) {
  #content-container{
    #content{
      padding: 48+12px 12px 12px;
    }
  }
}

@media screen and (min-width: $desktop-width) {
  .app.sticky-navi #content-container{
    overflow: auto;
  }
}
