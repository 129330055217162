@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

.center-div-outer {
  .center-div-middle{
    > :first-child.modify-article-modal{
      max-width: 1280px;
      .modal-content{
        padding: 0px;
      }
    }
  }
}


.modify-article-modal{
  .carousel-header{
    z-index: 100;
    position: sticky;
    top: 0px;
    margin: 0;
    padding-right: 40px;
    .items{
      > div{
        flex: 0;
        width: auto;
        padding: 0 40px;
      }
    }
  }
  .flex{
    display: flex;
    .fill-flex{
      flex: 1;
      margin-right: 20px;
    }
    .button-div{
      .button-element{
        button{
          padding-right: 40px;
        }
      }
    }
  }
  .modify-article-inputs,.modify-article-upload-image{
    background-color: $background-color;
    padding: 20px;
    overflow: auto;
  }
  .modify-article-preview{
    padding:20px;
  }
  textarea{
    line-height: 18px;
    font-size: 14px;
  }

  .buttons{
    display: flex;
    justify-content: space-between;
    .left{
      .button-element{
        margin-right: 20px;
      }
    }
    .left,.right{
      display: flex;
      align-items: center;
      .button-element{
        width: auto;
        button,input{
          padding-right: 40px;
        }
      }
    }
    
  }
  .model-select{
    margin-right: 12px;
  }
  .new-image-url-results{
    .image{
      img{
        max-height: 400px;
        max-width: 400px;
        border-radius: 4px;
        box-shadow: 0px 1px 3px 0px rgba(0,0,0,1);
      }
    }
  }
  .row{
    display: flex;
    width: 100%;
    > div{
      flex: 1;
      margin: 0 12px;
      &:first-of-type{margin-left: 0px;}
      &:last-of-type{margin-right: 0px;}
    }
  }
  .buttons.top{
    margin: 0 0 20px;
  }
  .note{
    font-size: 10px;
  }
}